import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { ErrorBoundary } from 'elements/boundary';
import ScrollToTop from 'lib/ScrollToTop';
import { AppSkeleton } from 'AppSkeleton';
import { RECAPTCHA_SITE_PUBLIC_KEY } from 'lib/Enum';
import { ROUTES } from './Routes';

import './App.scss';

const IS_DEV = ['development', 'test'].includes(process.env.NODE_ENV);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: IS_DEV ? 1 : 3,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Suspense fallback={<AppSkeleton />}>
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_PUBLIC_KEY.V3()}>
            <BrowserRouter>
              <ScrollToTop />
              {ROUTES()}
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
